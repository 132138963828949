import React from "react";
import styles from "./ImageHeader.module.css";
import "../style/style.css";

const ImageHeader = () => {
  return (
    <div className={styles.HeaderDiv + " Container"}>
      <div className={`${styles.ImageText}`}>Whatsapp x Cupra</div>
    </div>
  );
};

export default ImageHeader;
