import React from "react";
import "./App.css";
import ImageHeader from "./components/ImageHeader";
import AccordionContent from "./components/AccordionContent";
import Contact from "./components/Contact";
import Info from "./components/Info";
import Datenschutz from "./components/Datenschutz";
import "./style/style.css";
import "iframe-resizer/js/iframeResizer.contentWindow";

function App() {
  return (
    <div className="App">
      <div id="HeaderBackground">
        <ImageHeader />
      </div>
      <div style={{ backgroundColor: "#1b1b1b" }}>
        <Contact />
      </div>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Info />
      </div>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <AccordionContent />
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <Datenschutz />
      </div>
    </div>
  );
}

export default App;
