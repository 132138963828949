import styles from "./Accordion.module.css";
import useWindowDimensions from "./useWindowDimensions";

export default function Accordion(props: any) {
  const { width } = useWindowDimensions();
  return (
    <div className={`${styles.AccordionElem} mb-1`}>
      <button
        className="w-full p-4 text-left flex justify-between items-center
                         hover:bg-gray-300 transition duration-300"
        onClick={props.toggleAccordion}
      >
        {props.title}
        {props.isOpen ? (
          <span
            className={`${styles.AccordionIcon}  transform
                               transition-transform duration-300`}
          >
            &#8722;
          </span>
        ) : (
          <span
            className={`${styles.AccordionIcon} transform 
              transition-transform duration-300`}
          >
            &#43;
          </span>
        )}
      </button>
      {props.isOpen &&
        (width < 768 ? (
          <div
            className="p-4 w-11/12 text-base"
            dangerouslySetInnerHTML={{ __html: props.data }}
          />
        ) : (
          <div
            className="p-4 w-3/5 text-base"
            dangerouslySetInnerHTML={{ __html: props.data }}
          />
        ))}
    </div>
  );
}
