import React from "react";
import styles from "./Button.module.css";

import analyticsExternalLink from "../../cta_script/analyticsExternalLink";
import analyticsInternalLink from "../../cta_script/analyticsInternalLink";
import analyticsInteraction from "../../cta_script/analyticsInteraction";

const Button = (props: {
  cta_text: string;
  cta_link: string;
  eventName: string;
  moduleComponent: string;
  elementName: string;
  cta_type: string;
  left?: boolean;
}) => {
  const ButtonTracking = () => {
    switch (props.eventName) {
      case "externalLink":
        // setTimeout(() => {
        analyticsExternalLink(
          props.moduleComponent,
          props.elementName,
          props.cta_text,
          props.cta_type,
          props.cta_link
        );
        // }, 2000);
        break;
      case "internalLink":
        // setTimeout(() => {
        analyticsInternalLink(
          props.moduleComponent,
          props.elementName,
          props.cta_text,
          props.cta_type,
          props.cta_link
        );
        // }, 2000);
        // setTimeout(analyticsInternalLink, 2000);
        break;
      case "interaction":
        // setTimeout(() => {
        analyticsInteraction(
          props.moduleComponent,
          props.elementName,
          props.cta_text,
          props.cta_type,
          props.cta_link
        );
        // }, 2000);
        // setTimeout(analyticsInteraction, 2000);
        break;
      default:
        console.log("DEFAULT");
        return "default";
    }
  };

  return (
    <div>
      {props.eventName === "externalLink" ? (
        <a
          href={props.cta_link}
          target="_blank"
          rel="noreferrer"
          onClick={ButtonTracking}
          className={`${styles.Button} ${
            props.left ? styles.ButtonLeftSide : ""
          }`}
        >
          <span className={styles.ButtonText}>{props.cta_text}</span>
        </a>
      ) : (
        <a
          href={props.cta_link}
          target="_top"
          onClick={ButtonTracking}
          className={`${styles.Button} ${
            props.left ? styles.ButtonLeftSide : ""
          }`}
        >
          <span className={styles.ButtonText}>{props.cta_text}</span>
        </a>
      )}
    </div>
  );
};

export default Button;
