import React from "react";
import styles from "./Info.module.css";
import "../style/style.css";
import supportIcon from "../assets/CUPRA_WhatsApp_Support.png";
import kommunikationIcon from "../assets/CUPRA_WhatsApp_Kommunikation.png";
import interaktionIcon from "../assets/CUPRA_WhatsApp_Interaktion.png";

const TextBlock = (props: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  halfSize?: boolean;
}) => {
  return (
    <div className="Container">
      <div
        className={`${styles.TextBlock} ${
          props.halfSize ? styles.HalfBlock : ""
        }`}
      >
        <h2 className={styles.TextBlockTitle}>{props.title}</h2>
        {props.subtitle && (
          <h2 className={styles.TextBlockTitle}>{props.subtitle}</h2>
        )}
        <p className={styles.TextBlockInfo}>{props.children}</p>
      </div>
    </div>
  );
};

const FlowChartStep = (props: { step: string; children: React.ReactNode }) => {
  return (
    <div className={styles.StepContainer}>
      <h1 className={styles.FlowChartStepTitle}>{props.step}</h1>
      <p className={styles.FlowChartStepInfo}>
        <div className={styles.StepText}>{props.children}</div>
      </p>
    </div>
  );
};

const FlowChart = () => {
  return (
    <div className={`${styles.FlowChartContainer} Container`}>
      <div className={styles.StepListContainer}>
        <FlowChartStep step="1">
          QR -Code scannen <br />
          oder Link klicken.
        </FlowChartStep>
        <FlowChartStep step="2">WhatsApp öffnet sich.</FlowChartStep>
        <FlowChartStep step="3">Nachricht abschicken.</FlowChartStep>
      </div>
    </div>
  );
};

const BenefitElem = (props: {
  title: string;
  subtitle: string;
  img: string;
  noBorder?: boolean;
}) => {
  return (
    <div
      className={`${styles.BenefitElemContainer} ${
        props.noBorder ? styles.noBorder : ""
      }`}
    >
      <img
        src={props.img}
        alt="Icon"
        width="60"
        height="45"
        style={{ margin: "0 auto" }}
      ></img>
      <h2 className={styles.BenefitElemTitle}>{props.title}</h2>
      <h2 className={styles.BenefitElemSubtitle}>{props.subtitle}</h2>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className={styles.BenefitContainer + " Container"}>
      <h2 className={styles.BenefitTitle}>Vorteile</h2>
      <div className={styles.BenefitListContainer}>
        <BenefitElem
          img={kommunikationIcon}
          title="Kommunikation."
          subtitle="Bequem. In Echtzeit"
        ></BenefitElem>
        <div className={styles.BenefitDivider} />
        <BenefitElem
          img={supportIcon}
          title="Support."
          subtitle="Einfach. Persönlich. Individuell"
        ></BenefitElem>
        <div className={styles.BenefitDivider} />
        <BenefitElem
          img={interaktionIcon}
          title="Interaktion."
          subtitle="Per Smartphone. Von überall."
          noBorder
        ></BenefitElem>
      </div>
    </div>
  );
};

const Info = () => {
  return (
    <div>
      <TextBlock title="Get Started." halfSize>
        Wir sind von Montag bis Freitag zwischen 8 und 19 Uhr sowie Samstag
        zwischen 9 und 16:30 Uhr auf WhatsApp erreichbar. Wenn du uns außerhalb
        unserer Servicezeiten schreibst, erhältst du Hilfe von unserem Digital
        Assistant. Oder hinterlasse unseren Expertinnen und Experten eine
        Nachricht. Sie melden sich umgehend persönlich bei dir.
        <h2 className={styles.TextBlockSubTitle}>Simple. As That.</h2>
      </TextBlock>

      <FlowChart></FlowChart>
      <div className={styles.BenefitsContainer}>
        <Benefits />
      </div>
    </div>
  );
};

export default Info;
