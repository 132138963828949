export default function analyticsInternalLink(
  moduleComponent,
  sectionTitle,
  CTALabel,
  type,
  linkURL
) {
  var iframeData = [
    "internalLink",
    {
      eventName: "clickCTA",
      moduleComponent: moduleComponent, // name of the component (html class)
      elementName: sectionTitle, // HTML <h2> element. Ex: '100% ELECTRIC SUV', 'PERFORMANCE AND EFFICIENCY'
      CTALabel: CTALabel, // Label of the CTA
      CTAType: type, // name of the button id/class. e.g 'primary_CTA', 'secondary_CTA'
      linkURL: linkURL, // Value in href
    },
  ];
  console.log("iframedata: ");
  console.log(iframeData);
  window.top.postMessage(iframeData, "*");
}
// setTimeout(analyticsInternalLink, 2000);
