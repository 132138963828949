import React from "react";
import styles from "./Contact.module.css";
import Button from "./comp/Button";
import useWindowDimensions from "./useWindowDimensions";
import QRCode from "../assets/WhatsApp QR Code SVG.svg";
import WALogo from "../assets/CUPRA_WhatsApp_Icon.png";

const Contact = () => {
  const { width } = useWindowDimensions();
  return (
    <div className={styles.ContactContainer}>
      <div className={styles.ContactComponent + " Container"}>
        <div className={styles.ContactTextContainer}>
          <h1 className={styles.ContactTitle} style={{ lineHeight: 1 }}>
            Du hast Fragen?
          </h1>
          <h1 className={styles.ContactTitle}>Cupra chattet mit dir.</h1>
          <p className={styles.ContactText}>
            Wenn du mehr über CUPRA Fahrzeuge erfahren möchtest oder Fragen zu
            deinem CUPRA hast, kann dir der Whatsapp-Chat weiterhelfen. Einfach
            und bequem. Unser Team. Nur eine WhatsApp-Nachricht entfernt.
          </p>
        </div>
        {width < 768 && (
          <a href="https://wa.me/message/ZQCMH7HMQLNHI1" id={styles.WaLogo}>
            <img
              id={styles.WaPic}
              src={WALogo}
              width="75px"
              alt="logo"
              style={{ margin: "0 auto" }}
            ></img>
          </a>
        )}
        {width >= 768 ? (
          <div className={styles.ContactLinks}>
            <div className={styles.QrCodeContainer}>
              <div id={styles.QrCode}>
                <img
                  id={styles.QrPic}
                  src={QRCode}
                  height="105px"
                  alt="qr"
                  style={{ margin: "0 auto", backgroundColor: "white" }}
                ></img>
              </div>
            </div>
            <div className={styles.CTAButton}>
              <div>
                <Button
                  cta_link="https://wa.me/message/ZQCMH7HMQLNHI1"
                  cta_text="Chat starten"
                  eventName="externalLink"
                  moduleComponent="ContactLinks"
                  elementName="Chat starten"
                  cta_type="Primary CTA"
                ></Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.CTAButton}>
            <Button
              cta_link="https://wa.me/message/ZQCMH7HMQLNHI1"
              cta_text="Chat starten"
              eventName="externalLink"
              moduleComponent="ContactLinks"
              elementName="Chat starten"
              cta_type="Primary CTA"
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
